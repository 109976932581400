.catagories_section {
    background-image:url("https://preview.colorlib.com/theme/narosundar/assets/img/gallery/section-bg.jpg.webp");
    height: 100%;
    repeat: no-repeat;
}
.single_cat {
    width: 100%;
    background-color: #150303;
    color:white;
    font-size: 1rem;
    height: 100%;
    border-radius: 15px;
    position: relative;
    z-index: 0
}
.single_cat:hover {
    color:black;
}
.single_cat::before {
    content: " ";
    width: 100%;
    bottom: 0;
    position: absolute;
    left:0;
    height: 0.3rem;
    height: 0%;
    border-radius: 15px;
    background: white;
    transition: all 0.5s ease-in-out;
    z-index: -1;
}


.single_cat:hover::before {
    background-color: white;
    height: 100%;
    color: black;
    transition: all 0.4s ease;
}

.cat_caption {
    color: gray;
}