.About_sections {
    min-height: 90vh;
}
.check_icons {
    border-radius: 50%;
    font-size: 2rem;
    color: rgb(249, 121, 121);
}
.texts {
    font-family: var(--font2);
    font-size: 18px;
    font-weight: 400;
}
.About_sections img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


