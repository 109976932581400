.service_section .row {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1rem 0;
    
}

.service_section ul {
    width: 100%;
    border: none;
}
.btn-group {
    width: 80%;
}
.service_section .dropdownbtn {
    background-color: var(--primary-bgcolor);
    color: white;
    width: 80%;
    padding: 20px 10px 21px 34px;
    text-align: left;
    font-family: "Poppins", sans-serif;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
}
.service_section table {
    width: 80%;
    border: none;
    transition: all 0.4s ease;
}

.service_section tr {
    width: 100%;
    display: flex;
    font-family: "Poppins", sans-serif;
}
.service_section th {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.service_section span {
    color: var(--red);
    font-weight: bold;
}

.service_section .dropdownbtn:focus {
    background-color: var(--red);
}

.show {
    width: 100%
}
.service_section img {
    width: 80%;
}