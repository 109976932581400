.blog_cards {
    box-shadow: 0px 10px 20px 0px rgba(221,221,221,0.3);
}
.Blog_title {
    margin-top: 4rem;
    font-size: 1.4rem;
    font-weight: 800;
    cursor: pointer;
    transition: 0.4s;
}
.Blog_title:hover {
    color: var(--red);
}

.Blog_footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    gap: 0.3rem;
    cursor: pointer;
    transition: 0.4s;
}
.Blog_footer:hover {
    color: var(--red);
}

/**Side Blog **/
.blog_sidebar_search,.blog_sidebar_categories,.blog_sidebar_recent_post,.blog_sidebar_tags,.blog_sidebar_archive {
    background-color: var(--bg);
}
.blog_sidebar_search input {
    width: 100%;
    padding: 0.5rem;
    border: none;
    outline: none;
    border-bottom: 1px solid var(--red);
    background-color: white;
}
.blog_sidebar_search input::placeholder {
    color: grey;
    font-size: 0.8rem;
}
.Blog_sidebar_title {
    font-size: 1.2rem;
    font-weight: 800;
    margin-top: 1rem;
    border-bottom: 1px solid rgb(210, 207, 207);
    padding-bottom: 1.5rem;
}
.blog_sidebar_categories ul {
    list-style: none;
    padding: 0;
}
.blog_sidebar_categories li {
    padding: 0.8rem 0;
    cursor: pointer;
    transition: 0.4s;
    border-bottom: 1px solid rgb(210, 207, 207);
}
.blog_sidebar_categories li:last-child {
    border-bottom: none;
}
.blog_sidebar_categories li a {
    color: grey;
    transition: 0.4s;
}
.blog_sidebar_categories li a:hover {
    color: var(--red);
}

.blog_sidebar_categories li:hover {
    color: var(--red);
}

/* Recent Post */

.Blog_sidebar_recent_post_content {
    line-height: 12px;
}
