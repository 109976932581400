nav {
    left: 0;
    right: 0;
    width: 100%;
}
nav a {
    color: white;
    text-transform:capitalize;
    font-weight: 500;
}

.Logo_icon {
    color: var(--red);
}
.nav-item {
    position: relative;
    transition: all 0.5s ease-in-out;
}
.nav-item:hover {
    color: var(--red);
}
.nav-item::after {
    position: absolute;
    content: " ";
    width: 0%;
    bottom: 0;
    left:0;
    height: 1.5px;
    background: red;
    transition: all 0.5s ease-in-out;
}
.nav-item:hover::after {
    width: 100%;
}