@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
:root {
  --primary-bgcolor: #150303;
  --red:#FB0000;
  --font1:"Roboto", sans-serif;
  --font2:"Poppins", sans-serif;
  --bg:#FBF9FF;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background: #FB0000;
  border-radius: 12px;
}
::-webkit-scrollbar-track {
  background: #150303;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;;
}

.bg-primary2 {
  background-color: var(--primary-bgcolor);
  color: white;
}

.red {
  background-color: var(--red);
  color: white;
}

.titles {
  font-family: var(--font2);
  font-size: 3rem;
  font-weight: 800;
  text-transform: uppercase;
  position: relative;
}
.titles::before {
  content: " ";
  width: 100px;
  top: 0;
  position: absolute;
  left:0;
  height: 0.3rem;
  background: red;
  transition: all 0.5s ease-in-out;
}
.page {
  font-family: "Roboto",sans-serif;
  color: grey;
}

@media screen and (max-width: 426px) {
  .titles {
    font-size: 1.8rem;
  }
}

